@mixin Sub-Head {
  font-size: 30px;
  font-weight: 500;
}
@mixin Para {
  font-size: 14px;
  font-weight: 400;
}
.GmatGreMain{
    font-family: "Poppins", sans-serif !important;
  padding-top: 180px;
  padding-bottom: 50px;
    h5{
        font-size: 19px;
        font-weight: 500;
      } 
      h6{
        font-size: 17px;
        font-weight: 500;
        padding: 10px 0;
      } 
      p {
        @include Para();
        color: #363636;
      }
      .SubText{
        margin-left: 20px;
      }
      .TextPoints {
        position: relative;
        margin-left: 20px;
        &::before {
          content: "";
          position: absolute;
          top: 5px;
          left: -20px;
          height: 6px;
          width: 6px;
          border-radius: 20px;
          background-color: #1b4e9b;
        }
      }
      .TextColorPoint{
          position: relative;
          margin-left: 20px;
          &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: -20px;
            height: 6px;
            width: 6px;
            border-radius: 20px;
            background-color: #bccf31;
          }
      }
      .TextPointsBold {
        font-weight: 500 !important;
        padding: 5px 0;
        @include Para();
      }
}