@mixin Para {
  font-size: 14px;
  font-weight: 300;
}
.FooterMain {
  font-family: "Poppins", sans-serif;
  background-color: #272626;
 padding-top: 40px;
 padding-bottom: 30px;
  p {
    color: #ffff;
    @include Para();
  }
  h3 {
    color: #fff;
    font-size: 20px;
  }

  .FooterSec {
    display: flex;
    padding: 20px 0;
    .AddressSec {
      padding-top: 30px;
      flex-basis: 40%;
      .Text {
        padding-top: 20px;
        padding-bottom: 40px;
        p {
          width: 290px;
          @include Para();
        }
      }
      .LogoFooter {
        img {
          width: 130px;
          object-fit: contain;
        }
      }

      .SocialSec {
        display: flex;
        .RightSocial {
          ul {
            display: flex;
            li {
              margin-right: 10px;
              @include Para();
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
    .ExmSec {
      padding-top: 30px;

      flex-basis: 30%;
      .SubText {
        padding-top: 15px;
      }
    }
    .ContactSec {
      padding-top: 30px;
      flex-basis: 50%;
      p{
        padding-left: 50px;
        @include Para();
      }
      .BulletTextA {
        padding-top: 15px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          left: 0px;
          top: 18px;
          width: 80px;
          height: 80px;
          background-image: url("../../public/assets/images/contact-icon-3.svg");
          background-repeat: no-repeat;
          background-size: 20px;
        }
        
      }
      .BulletTextB {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          left: 0px;
          top: 5px;
          width: 80px;
          height: 80px;
          background-image: url("../../public/assets/images/contact-icon-2.svg");
          background-repeat: no-repeat;
          background-size: 23px;
        }
        
      }
      .BulletTextC {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          left: 0px;
          top: 0px;
          width: 80px;
          height: 80px;
          background-image: url("../../public/assets/images/contact-icon-1.svg");
          background-repeat: no-repeat;
          background-size: 20px;
        }
        
      }
      .BulletTextD {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          left: 0px;
          top: 0px;
          width: 80px;
          height: 80px;
          background-image: url("../../public/assets/images/contact-icon-4.svg");
          background-repeat: no-repeat;
          background-size: 20px;
        }
        
      }
    }
    span {
      height: 200px;
      background-color: #70707087;
      width: 1px;
      margin-right: 50px;
      margin-top: 20px;
    }
  }
  .CopyRight {
    p {
      text-align: center;
      @include Para();
    }
  }
}

@media screen and (max-width: 768px) {
  .FooterMain {
    padding: 20px 20px;

    .FooterSec {
      display:block;
      padding: 20px 0;
      .AddressSec {
        padding-top: 10px;
        .Text {
          padding-top: 20px;
          padding-bottom: 10px;
          p {
            width: 100%;
          }
        }
       

      }
      .ExmSec {
        padding-top: 0px;
       
        .SubText {
          padding-top: 8px;
        }
      }
      .ContactSec {
        padding-top: 15px;
        
       
      }
      span {
       display: none;
      }
    }
  
  }
}