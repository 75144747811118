$primary-color: #1b4e9b;
$main-body: #f0f5fb;
$secondary-color: #bccf31;
$sub-text: #9d9e9e;

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
}
a {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  outline: none !important;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

img {
  max-width: 100%;
  height: auto;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
li {
  margin: 0;
  padding: 0;
}
p {
  padding: 0;
  color: #444;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  margin: 0 0 15px 0;
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: #151515;
}
input,
select,
button {
  outline: none !important;
}
/*html{scroll-behavior: smooth; -webkit-scroll-behavior: smooth; -moz-scroll-behavior: smooth;}*/
body {
  font-family: "Poppins", sans-serif;
}
html {
  scrollbar-color: #1b4e9b #444;
  scrollbar-width: thin;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background: #444;
}
body::-webkit-scrollbar-thumb {
  background: #1b4e9b;
}
body::-moz-scrollbar {
  width: 8px;
}
body::-moz-scrollbar-track {
  background: #444;
}
body::-moz-scrollbar-thumb {
  background: #1b4e9b;
}
body::-o-scrollbar {
  width: 8px;
}
body::-o-scrollbar-track {
  background: #444;
}
body::-o-scrollbar-thumb {
  background: #1b4e9b;
}
body::-ms-scrollbar {
  width: 8px;
}
body::-ms-scrollbar-track {
  background: #444;
}
body::-ms-scrollbar-thumb {
  background: #1b4e9b;
}
.headerMainAll {
  position: fixed;
  top: 0;
  padding-left: 270px;
  padding-top: 0px;
  width: 100%;
  padding-right: 17px;
  z-index: 100;
}
.scrolled {
  background: #f7f7f7;
  -webkit-box-shadow: -5px 1px 32px -9px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -5px 1px 32px -9px rgba(0, 0, 0, 0.25);
  box-shadow: -5px 1px 32px -9px rgba(0, 0, 0, 0.25);
}
.allPageLayOutBg {
  position: relative;
  top: 0px;
  padding-left: 279px;
  padding-top: 72px;
  padding-right: 15px;
}
.App {
  text-align: center;
  color: $primary-color;
  background-color: $sub-text;
}

.main1div {
  .card-body {
    padding: 0px !important;
    margin-bottom: 10px;
  }
  .card {
    background-color: transparent !important;
    border: none !important;
    padding: 0px !important;
    width: 100%;
  }
  .card-header {
    background-color: transparent !important;
    border: none !important;
    padding: 0px !important;
    margin-bottom: 10px;
    .buttonCommonStyle {
      font-weight: 300;
      font-size: 15px;
      border-radius: 30px;
      border: none;
      width: 100%;
      background: none !important;
      color: #000;
      position: relative;
      text-align: left;
      padding: 10px 51px;

      img {
        position: absolute;
        left: 14px;
        top: 12px;
        margin-bottom: 0px;
        width: 30px;
      }
    }
    .activebuttonCommonStyle {
      background-color: $primary-color;
      font-weight: 300;
      font-size: 15px;
      border-radius: 30px;
      border: none;
      width: 100%;
      color: #fff;
      position: relative;
      text-align: left;
      padding: 10px 51px;

      img {
        position: absolute;
        left: 14px;
        top: 12px;
        margin-bottom: 0px;
        width: 30px;
      }
    }
    .activeBgMain {
      background: #1b4e9b !important;
    }
  }

 
  .nav {
    display: block !important;
  }
  .dropdown-menu.show {
    transition: 0.4s;
    visibility: visible;
    opacity: 1;
  }

  .dropdown-menu {
    border: none !important;
    width: 100% !important;
    background: transparent !important;
    padding: 0px;
    visibility: hidden;
    opacity: 0;
    top: 43px !important;
    transition: 0.7s;
    display: block !important;
    inset: 0px !important;
    transform: translate(0px, 42px) !important;
  }
}

.commonClassDivHeader {
  .form-control {
    border: none !important;
    padding: 7px 39px;
    background: #fff;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 300;
    width: 200px;
    margin-left: 11px;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
  }
  .form-control:focus {
    box-shadow: none !important;
    width: 250px;
  }
}
.LinkTo {
  background-color: #ffff;
  padding: 12px 30px !important;
  text-align: center;
  border-radius: 28px;
  font-size: 14px;
}


.slider1MainAll {
  padding-top: 160px;
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    display: flex;
    text-align: center;
    background-color: #1b4e9b !important;
    color: #fff;
    border-radius: 20px;
    padding: 10px 10px !important;
    width: 100px;
    font-size: 12px;
    border: none;
  }
  .nav-pills .nav-link {
    display: flex;
    text-align: center !important;
  }
  .nav-link {
    display: flex;
    padding: 10px 10px !important;
    width: 130px;
    text-align: center !important;
    border-radius: 20px;
    font-size: 13px;
    cursor: pointer;
    position: relative;
  
  }
  .nav .active{
    background-color: #1b4e9b !important;
    z-index: 1;
    text-align: center !important;
    display: inline-block;
    color: #fff !important;
  }
  .leftButtonActive {
    background-color: #1b4e9b !important;
    color: #fff;
    z-index: 1;
    text-align: center !important;
    display: inline-block;
    &:hover{
      color: #ffff;
    }
  }
  .leftButton {
    background-color: #ffff;
    color: #000 !important;
    text-align: center !important;
    background-color: transparent;
    border: 1px solid #e5e5e5;
    font-size: 13px;
    display: inline-block;
    position: relative;
  }
  .ActiveLeft{
   position: relative;
   margin-left: -20px;
  }

  .nav {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 30px;
  }
}

.GalleryMainCommon {
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    display: none !important;
  }
}

.accordionMainAll {
  h3 {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: block;
      padding-bottom: 7px;
      position: relative;
      padding-left: 21px;
      &:before {
        position: absolute;
        left: 0;
        top: 10px;
        content: "";
        width: 6px;
        border-radius: 50%;
        height: 6px;
        background-color: #3260a5;
      }
    }
  }
  // .accordion-button::after{background-image: url('../public/assets/images/dropicon-png.png');
  //   background-repeat: no-repeat;
  //   background-position: center; background-size: 14px!important;
  //  }
  .accordion-button {
    padding: 0px;
  }
  .accordion-item {
    border: none !important;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 19px 20px;
    margin-bottom: 10px;
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }
  .accordion-button:not(.collapsed) {
    background: none !important;
    color: #000;
    box-shadow: none;
  }
  .accordion-body {
    padding: 11px 0 0px;
    border-top: 1px #ddd solid;
    margin-top: 9px;
  }
}

.personalFieldDiv {
  .btn-primary:focus {
    box-shadow: none !important;
  }
  .savebtnMain {
    background: #bccf31;
    border: 1px solid #bccf31;
    border-radius: 4px;
    padding: 10px;
    width: 246px;
    height: 40px;
    font-weight: normal !important;
    color: #fff !important;
    font-weight: 500 !important;
    border: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 0px;

    &:hover {
      background: #1b4e9b;
    }
  }

  .nextbtnMain {
    background: #1b4e9b;
    border: 1px solid #1b4e9b;
    border-radius: 4px;
    padding: 10px;
    width: 246px;
    height: 40px;
    font-weight: normal !important;
    color: #fff !important;
    font-weight: 500 !important;
    float: right;
    border: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 0px;
    &:hover {
      background: #bccf31;
    }
  }
  .form-label {
    font-size: 13px !important;
    font-weight: normal !important;
    color: #2c2c2c !important;
    margin-bottom: 5px;
  }
  .form-select {
    padding: 7px 8px;
    background-size: 12px 14px !important;
    opacity: 1;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #e2e2e2 !important;
    border-radius: 4px !important;
    color: #1c1919;
    width: 100% !important;
    display: block;
  }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }
  .form-control:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }
  .input-group {
    display: block !important;
  }
  .form-control {
    padding: 7px 8px;
    background-size: 12px 14px !important;
    opacity: 1;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #e2e2e2 !important;
    border-radius: 4px !important;
    color: #1c1919;
    width: 100% !important;
    display: block;
  }
}

.formPostionWrapper {
  position: relative;
  padding-left: 48px;
  .form-control {
    background: none !important;
    padding: 7px 5px;
    border-left: none !important;
    border-radius: 0px 6px 6px 0px !important;
  }
  .formPostionWrapperDiv {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 53px;
    &:before {
      position: absolute;
      right: 6px;
      top: 10px;
      content: "";
      width: 1px;
      height: 18px;
      background-color: #ddd;
      z-index: 10;
    }
    .form-select {
      border-right: none !important;
      padding: 8px 2px;
      border-radius: 6px 0px 0px 6px !important;
      background-size: 9px 9px !important;
      font-size: 12.5px;
    }
  }
}

.div .nav {
  overflow-x: auto !important;
  overflow-y: auto !important;
  flex-wrap: nowrap !important;
}

.brand-wrapper-main {
  .brand-main {
    .owl-carousel .owl-nav.disabled,
    .owl-carousel .owl-dots.disabled {
      display: block !important;
    }

    .owl-carousel .owl-nav {
      position: relative;
      button.owl-prev {
        position: absolute;
        left: -30px;
        top: -65px;
        span {
          font-size: 35px;
          font-weight: 200;
          color: #3260a5;
        }
      }
      button.owl-next {
        position: absolute;
        right: -30px;
        top: -65px;
        span {
          font-size: 35px;
          font-weight: 200;
          color: #3260a5;
        }
      }
    }
  }
  .brand-main-one {
    .owl-carousel .owl-nav.disabled,
    .owl-carousel .owl-dots.disabled {
      display: block !important;
    }

    .owl-carousel .owl-nav {
      position: relative;
      button.owl-prev {
        position: absolute;
        left: -30px;
        top: -95px;
        span {
          font-size: 35px;
          font-weight: 200;
          color: #3260a5;
        }
      }
      button.owl-next {
        position: absolute;
        right: -30px;
        top: -95px;
        span {
          font-size: 35px;
          font-weight: 200;
          color: #3260a5;
        }
      }
    }
  }
}

.commonPopupmainSecCommon {
  position: relative;
  padding: 40px 60px;

  font-family: "Poppins", sans-serif;
  .close-icon {
    position: absolute;
    right: -50px;
    top: -60px;
  }
  .form-check-label {
    font-size: 13px !important;
    color: #968d8d !important;
  }
  .continuesBtnMain {
    width: 100%;
    cursor: pointer;
    background: #1b4e9b;
    color: #fff;
    text-align: center;
    font-size: 15px;
    border: none;
    min-width: 160px;
    min-height: 48px;
    display: block;
    border-radius: 0px;
  }
}
.modal-content {
  border-radius: 0.9rem !important;
  min-width: 470px !important;
  margin-top: 100px;
  @media only screen and (max-width: 767px) {
    min-width: 94vw !important;
  }
  @media only screen and (max-width: 570px) {
    padding: 20px;
  }
}
.form-control {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  height: 45px;
  padding: 0 5px;
  line-height: 28px;
  font-size: 15px;
  color: #333333 !important;
  margin-top: 15px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
::placeholder {
  color: #b9b9b9 !important;
  font-size: 15px !important;
}

.form-check-input[type="checkbox"] {
  border-radius: 1px !important;
}

.slect-main {
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
  height: 45px;
  width: 100%;
  padding: 0 5px;
  line-height: 28px;
  font-size: 15px;
  color: #333333 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  option:first {
    color: #b9b9b9 !important;
  }
}
.option-head {
  color: #b9b9b9 !important;
}

@media screen and (max-width: 982px) {
  .brand-wrapper-main {
    .brand-main {
      .owl-carousel .owl-nav {
        button.owl-prev {
          left: 0px;
          top: 0px;
        }
        button.owl-next {
          right: 0px;
          top: 0px;
        }
      }
    }
    .brand-main-one {
      .owl-carousel .owl-nav {
        button.owl-prev {
          left: 0px;
          top: 0px;
        }
        button.owl-next {
          right: 0px;
          top: 0px;
        }
      }
    }
  }
  .commonPopupmainSecCommon {
    padding: 20px 20px;
    .close-icon {
      position: absolute;
      right: -20px;
      top: -60px;
    }
  }
  .modal-content {
    border-radius: 0.9rem !important;
    min-width: 100% !important;
    width: 100%;
    margin-top: 80px;
    @media only screen and (max-width: 767px) {
      min-width: 94vw !important;
    }
    @media only screen and (max-width: 570px) {
      padding: 20px;
    }
  }
  .offcanvas-header {
    justify-content: end !important;
    background-color: #f8fbff;
    color: #fff;
  }
  .offcanvas-body {
    background-color: #f8fbff;
    a{
      color: #1b4e9b !important;
    }
    .navbar-nav .nav-link {
      color: #1b4e9b !important;
    }
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("../public/assets/images/burger.png") !important;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .navbar-toggler {
    border: none !important;
    transition: none !important;
    position: absolute;
    right: 15px;
    top: 0px;
  }
  .modal {
    padding-left: 0 !important;
  }
  .Whitebox {
    width: 100% !important;
    height: auto;

    .marquee {
      display: block;
      position: relative;
      animation: scroll 10s linear infinite;
      p {
        font-size: 14px;
        color: #555353;
        font-weight: 500;
        position: relative;
        margin-left: 60px;
        &::before {
          content: url("https://xatonline.in/downloads/619627666199f951449624_Update.gif");
          position: absolute;
          left: -60px;
        }
      }
    }
  }
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
span.navbar-toggler {
  border: none !important;
  transition: none !important;
  float: right !important;
}
.navbar {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.bg-on {
  background-color: #f8fbff !important;
}
.boxShade {
  box-shadow: 2px 8px 6px -6px rgba(0, 0, 0, 0.16);
  
}

.errorMessage {
  border: red;
  color: red;
  font-size: 11px;
  position: relative;
  top: 0;
}

.table > :not(:first-child) {
  border-top: transparent !important;
}
.prospectusMainSec {
  .card {
    border: none !important;
    .card-header {
      padding: 0.5rem 1rem;
      margin-bottom: 0;
      background-color: #fff !important;
      border-bottom: none !important;
      border: none;
      padding: 0rem 0rem !important;
    }
  }
}
.faqSection {
  .accordion-item {
    border: none;
    margin-top: 10px;
    border-radius: 0px !important;
  }
  .accordion-body {
    padding: 10px 30px !important;
  }
  .accordion-button {
    color: #000000;
    opacity: 1;
    box-shadow: none;
    border-radius: 0px;
    font-size: 16px;
    padding: 9px 19px;
    font-weight: 500;
    border-top: 1px #ddd solid !important;
    &::after {
      transform: rotate(-90deg) !important;
      background-size: 17px !important;
    }
  }

  .accordion-button:focus {
    box-shadow: none !important;
  }
  .accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #ffff;
    box-shadow: none;
    border: none !important;
    border-radius: 0px !important;
    &::after {
      transform: rotate(0deg) !important;
      background-size: 17px !important;
    }
  }
  .accordion-button:first-child {
    border-top: none;
  }

  .accordion-item:first-of-type .accordion-button {
    border-top: none !important;
  }
  // .accordion-item:last-of-type .accordion-button.collapsed {
  //   border-bottom-left-radius: 0px !important;
  //   border-bottom-right-radius: 0px !important;
  // }
  .activeBtn {
    .dropdown-toggle {
      border-radius: 0px;
    }
  }
  .innerFaq {
    .accordion-button:not(.collapsed) {
      color: #000 !important;
      background-color: #edf3fc;
      box-shadow: none;
      border: none !important;
      border-radius: 0px !important;
    }
    .accordion-body {
      padding: 10px 0px !important;
    }
    .accordion-button {
      background-color: #edf3fc;
      color: #000000;
      opacity: 1;
      box-shadow: none;
      border-radius: 0px;
      font-size: 14px;
      padding: 9px 19px;
      font-weight: 500;
      border-top: 1px #ddd solid !important;
      &::after {
        display: none;
      }
    }
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}
.Whitebox {
  margin-top: 15px;
  background-color: #fff;
  padding: 20px 15px;
  width: 500px;
  height: 300px;
  overflow: hidden;
  .marquee {
    display: block;
    position: relative;
    animation: scroll 10s linear infinite;
    p {
      font-size: 14px;
      color: #555353;
      font-weight: 500;
      position: relative;
      margin-left: 60px;
      &::before {
        content: url("https://xatonline.in/downloads/619627666199f951449624_Update.gif");
        position: absolute;
        left: -60px;
      }
    }
  }

  .marquee:hover {
    animation-play-state: paused;
  }

  @keyframes scroll {
    0% {
      top: 400px;
    }
    100% {
      top: -800px;
    }
  }
}
.mainNav{
  a.nav-link{
    font-size: 14px;
    font-weight: 500;
    border-radius: 20px; 
    &:hover{
      color: #fff;
      background-color: #bccf31;
      border-radius: 20px; 
      padding: 15px 15px !important;
    
    }
  }
 
}
.VideoPopup{
  .modal{
    background-color: transparent !important;
  }
  padding: 0;
  margin: 0;
  position: relative;
  background-color: transparent;
  .close-icon {
    position: absolute;
    right: -50px;
    top: -60px;
  }
  iframe{
    border-radius: 5px;
  }
}