@mixin Para {
  font-size: 14px;
  font-weight: 400;
}
@mixin Sub-Head {
  font-size: 30px;
  font-weight: 500;
}
.ProspectusSecMain {
  font-family: "Poppins", sans-serif !important;
 padding-top: 190px;
 padding-bottom: 40px;
  h1 {
    @include Sub-Head();
    
    padding: 10px 0;
  }
  p {
   @include Para();
    color: #363636;
  }
  h6 {
   font-size: 15px;
   font-weight: 600;
    color: #0d0d0d;
  }
  h5 {
    font-size: 20px;
    font-weight: 600;
  }
  .ProspectusButton {
    cursor: pointer;
    text-align: center;
    background-color: #1b4e9b;
    color: #fff;
    border-radius: 20px;
    padding: 12px 12px !important;
    width: 380px;
    font-weight: 400;
    font-size: 13px;
    border: none;
    margin-bottom: 10px;
  }
  .ButtonOne {
    cursor: pointer;
    text-align: center;
    background-color: #1b4e9b;
    color: #fff;
    border-radius: 20px;
    padding: 10px 10px !important;
    width: 100px;
    font-size: 14px;
    border: none;
    margin-top: 10px;
  }
  ol {
    list-style: upper-latin;
    padding-left: 0.8rem;
    li {
      font-size: 14px;
      font-weight: 600;
      line-height: 2;
      color: #191919;
      &:hover {
        color: #1b4e9b;
      }
    }
  }
  .TextPoints {
    position: relative;
    margin-left: 20px;
    line-height: 1;
    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: -20px;
      height: 6px;
      width: 6px;
      border-radius: 20px;
      background-color: #1b4e9b;
    }
  }
}
@media screen and (max-width: 768px) {
  .ProspectusSecMain {
    .ProspectusButton {
      width: 100%;
    }
  }
}
