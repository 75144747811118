.mainStickyDivWrapper {
  width: 100%;
  // background-color: #fff;
  // padding: 20px;
  border-radius: 4px;
  margin-top: 10px;
  overflow-x: hidden;
  .formWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    :global {
      .form-select,
      .form-control {
        border-radius: 0px !important;
        margin-top: 0px !important;
        height: 35px !important;
        &::placeholder {
          color: rgb(170, 169, 169);
          font-size: 13px !important;
        }

      }
    }
    .formSection {
      width: 100%;
      .innerSection {
        margin: 10px 10px;
      }
      h4 {
        margin: 10px;
      }
      .labelWithValue {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin: 10px;
        gap: 10px;
        p {
          margin-bottom: 0;
          font-size: 17px;
        }
        h6 {
          margin-bottom: 0;
          font-size: 17px;
        }
      }
    }
    .innerButton {
      display: flex;
      align-items: center;
      margin-top: 10px;

      button {
        margin-right: 20px;
      }
    }

    .formSection {
      background-color: transparent;
      width: 50%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      .innerSection {
        margin: 10px 10px;
      }
      .innerLabelSection {
        margin: 10px 10px;
      }

      h4 {
        margin: 10px;
        font-size: 15px;
        font-weight: 400;
      }

      .labelWithValue {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin: 10px;
        gap: 10px;

        p {
          margin-bottom: 0;
          font-size: 17px;
        }

        h6 {
          margin-bottom: 0;
          font-size: 17px;
        }
      }
    }
    .radioSection{
      background-color: transparent;
      width: 100%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      .innerSection {
        margin: 10px 10px;
      }
      .innerLabelSection {
        margin: 10px 10px;
      }

      h4 {
        margin: 10px;
        font-size: 15px;
        font-weight: 400;
      }

      .labelWithValue {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin: 10px;
        gap: 10px;

        p {
          margin-bottom: 0;
          font-size: 17px;
        }

        h6 {
          margin-bottom: 0;
          font-size: 17px;
        }
      }
    }
    .formLabelSection {
      background-color: transparent;
      width: 100%;
      .innerSection {
        margin: 10px 10px;
      }
      .innerLabelSection {
        margin: 10px 10px;
      }

      h4 {
        margin: 10px;
        font-size: 14px;
        font-weight: 400;
      }

      .labelWithValue {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin: 10px;
        gap: 10px;

        p {
          margin-bottom: 0;
          font-size: 17px;
        }

        h6 {
          margin-bottom: 0;
          font-size: 17px;
        }
      }
    }

    .innerButton {
      display: flex;
      align-items: center;
      margin-top: 10px;
      width: 100%;
      justify-content: center;
      align-items: center;

      button {
        margin-left: 5px;
        border-radius: 0px !important;
        padding-inline: 35px;
        background: #8faa44;
        padding-block: 3px;
        border: none;
      }
    }
  }
}

.generationWrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
  .generateMain {
    display: flex;
    gap: 10px;
    .leftWrapper {
      width: 70%;
      height: 100%;
      margin: 20px;
      border: 1px solid gainsboro;
      border-radius: 4px;
      .header {
        background-color: #1b4e9b;
        padding: 10px;
        border-radius: 4px;
        border-bottom: 1px solid gainsboro;
        color: #fff;
      }
      .textContainerWrapper {
        position: relative;
        height: 100%;
        margin: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        .mainHeader {
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          padding: 10px;
          border-bottom: 1px solid gainsboro;
        }
        .mainContainer {
          padding: 20px;
          .formsMain {
            display: block;
          }
          button {
            width: 100px;
            padding: 10px 10px;
            background-color: #1b4e9b;
            border: none;
            color: #fff;
            font-size: 14px;
            border-radius: 20px;
            font-weight: 400;
            margin-top: 10px;
          }
          .added {
            width: 50px;
            position: absolute;
            bottom: 15px;
            right: 51px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .rightWrapper {
      width: 30%;
      height: 100%;
      margin: 20px;
      border: 1px solid gainsboro;
      border-radius: 4px;
      .toolBox {
        background-color: whitesmoke;
        padding: 10px;
        border-bottom: 1px solid gainsboro;
      }
      ul {
        border-radius: 0;
        li {
          cursor: pointer;
        }
      }
      .saveBtn {
        width: 100%;
        border: 0;
        border-radius: 0%;
        background-color: #1b4e9b;
        padding: 10px;
      }
    }
  }
}

.formContainerMain {
  display: flex;
  align-items: center;

  h6 {
    margin: 0 !important;
  }

  .closeBtn {
    :global {
      .btn {
        background-color: transparent !important;
        border: 1px solid black !important;
        padding: 0 !important;
        width: fit-content !important;
        aspect-ratio: 1 !important;
        margin: 0 !important;
        margin-left: 16px !important;
      }
    }


    button {
      width: 100px;
      padding: 10px 10px;
      background-color: transparent;
      border: 1px solid;
      color: #fff;
      font-size: 14px;
      border-radius: 20px;
      font-weight: 400;
      border: 1px solid black;
    }
  }
}

.mainFormWrapperContainer {
  width: 100%;
}

.activeRadio {
  border: 3px solid lightblue;
  padding: 10px;

  
}

.radioImageWrapper {
  cursor: pointer;
  img {
    max-width: 200px;
  }
  text-align: center;
}

.imageRadioField {
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 765px) {
    display: block !important;
  }
}

.countInput {
  border: 1px solid gainsboro !important;
  text-align: center !important;
  font-family: inherit !important;
}


.DropdownField{
  position: relative;
  .DropdownLabelField{
    position: absolute;
    top: -8px;
    font-size: 12px;
    left: 8px;
    background: #fff;
    padding: 0px 3px;
  }
}
