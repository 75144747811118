.rankingSecMain {
    padding: 100px 0;
    background-image: url(../../../public/assets/images/landing-page-banner-new.png);
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    position: relative;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 1199px) {
      height: 100%;
      padding: 50px 0px 50px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to right,
        rgba(16, 49, 106, 0.8),
        rgba(16, 49, 106, 0.51)
      );
    }
 

  h1 {
    color: #fff;
    font-weight: 500;
    font-size: 38px;
    text-align: left;
    margin-bottom: 26px;
    line-height: 31px;
  }
}


.contactTxtDiv {
    z-index: 1000;
    h3 {
      color: #fff;
      font-weight: 400;
      font-size: 42px;
      text-align: left;
     
      margin-bottom: 20px;
      line-height: 55px;
      max-width: 500px;
      margin-top: 0;
      @media screen and (max-width: 767px) {
        font-size: 38px;
        margin-bottom: 6px;
        line-height: 50px;
      }
    }
    h4 {
      background-color: transparent;
      color: #a2caf5;
      font-weight: 400;
      border: 1px solid rgba(162, 202, 245, 0.65);
      font-size: 14px;
      width: fit-content;
      font-family: "Sora", sans-serif;
      padding: 15px 25px;
      margin-top: 40px;
      b {
        margin-left: 10px;
      }
    }
    p {
      color: #fff;
      font-weight: 300;
      font-size: 14px;
      text-align: left;
      margin-bottom: 0px;
      line-height: 1.63;
      font-family: "Sora", sans-serif;
      max-width: 545px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    .details {
      padding: 0;
      margin-top: 25px;
      margin-bottom: 12px;
      li {
        display: inline-block;
        border-right: 1px solid;
        list-style: none;
        color: #fff;
       
        font-size: 14px;
        font-weight: 400;
        padding-right: 10px;
        padding-left: 10px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border: 0;
        }
      }
    }

    .btnWrapper {
      list-style: none;
      margin: 40px 0 30px 0;
      padding: 0px;
      display: flex;
      li {
        margin-right: 10px;
        img{
            width: 6px;
            margin-left: 6px;
        }
        .applyBtnmain {
          background: #8faa44;
          font-size: 15px;
          font-weight: 500;
      
          text-align: center;
          color: #fff;
          padding: 15px 27px;
          width: 180px;
          border: none;
          transition: 0.4s;
          line-height: 1;
          @media screen and (max-width: 570px) {
            width: 100%;
          }
       
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .contactTxtDiv1 {
  
    background: #ffff;
    width: 425px;
    padding: 30px;
    border-radius: 5px;
    @media screen and (max-width: 1199px) {
      margin-top: 20px;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      padding: 20px;
    }
    .FlexWrap{
        display: flex;
        padding-bottom: 10px;
        margin: -7px;
       input{
           margin: 7px;
       }
    }
    h2 {
      color: #10316a;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      margin-bottom: 15px;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
    }
    .contactBoxTxt {
   
      margin-top: 10px;
      :global(.form-check-input){
          padding: 0;
          width: 20px;
          margin-right: 15px;
      }
      label{
font-size: 12px;
      }
   
    }
    input {
      width: 100%;
      margin-bottom: 14px;
      outline: none;
      padding: 9px 14px;
      box-shadow: none !important;
      color: #020202;
      border: solid 1px rgba(110, 111, 113, 0.29) !important;
      font-weight: 400;
      background: none !important;
      margin-right: 5px;
      &::placeholder {
        color: black !important;
      }
    }
    textarea {
      padding: 9px 14px;
      outline: none;
      margin-bottom: 14px;
      width: 100%;
      border: solid 1px rgba(110, 111, 113, 0.29) !important;
      border-radius: 0px;
      font-weight: 600;
      color: black !important;
      font-size: 16px;
      background: none !important;

      &::placeholder {
        color: black !important;
      }
    }
    button {
      width: 100%;
      background: #8faa44;
      color: #fff;
      border-radius: 2px;
      width: 100%;
      height: 20px;
      border: none;
      height: 43px;
      font-size: 15px;
      font-weight: 500;
      transition: 0.4s;
      img{
        width: 6px;
        margin-left: 6px;
    }
      &:hover {
        background: #000 !important;
      }
    }
  }

  .WhiteBox{
      z-index: 1000;
  }