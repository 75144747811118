.HeaderMain {
  font-family: 'Poppins', sans-serif;
  padding-top: 30px;
  background-color: #f8fbff !important;
 position: fixed; width: 100%;
 z-index: 100 !important;
 margin-bottom: 80px;
 top: 0;
  .headerMainSec {
    display: flex;
    justify-content: space-between;
    .Logo {
      img {
        width: 130px;
      }
    }
    .MenuItem {
      display: flex;
      .MenuLink {
        text-align: center;
        background-color: #1b4e9b;
        margin-right: 10px;
        color: #fff;
        border-radius: 20px;
        padding: 10px 10px !important;
        width: 100px;
        height: 38px;
        font-size: 12px;
        &:hover{
          color: #fff;
          background-color: #bccf31;
        }
       
      }
      .MenuLinkActive {
        text-align: center;
        background-color: #bccf31;
        margin-right: 10px;
        color: #fff;
        border-radius: 20px;
        padding: 10px 10px !important;
        width: 100px;
        font-size: 12px;
        &:hover{
          color: #fff;
        }
       
        
      }
    }
  }
  .MenuBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0;
    .MenuBarLink {
      font-size: 13px;
      color: #fff;
    
    }
  }
  .NavbarSec{
   margin-top: 20px;
    color: #fff;
    background-color: #1b4e9b;
    height:50px;
    
  }
}




@media screen and (max-width: 768px) {
  .HeaderMain {
    font-family: 'Poppins', sans-serif;
  
   padding-bottom: 20px;
   padding-top: 30px;
    .headerMainSec {
      display:flex;
    
      .Logo {
        img {
          width: 90px;
        }
      }
      .MenuItem {
        display: flex;
        .MenuLink {
          text-align: center;
       
          margin-right: 10px;
          color: #fff;
          border-radius: 20px;
          padding: 10px 10px !important;
          width: 70px;
          font-size: 10px;
          &:hover{
            color: #fff;
            background-color: #bccf31;
          }
         
        }
        .MenuLinkActive {
          text-align: center;
          background-color: #bccf31;
          margin-right: 10px;
          color: #fff;
          border-radius: 20px;
          padding: 10px 10px !important;
          width: 100px;
          font-size: 12px;
          &:hover{
            color: #fff;
          }
         
          
        }
      }
    }
    .MenuBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 20px 0;
      .MenuBarLink {
        font-size: 13px;
        color: #fff;
        &:hover{
          color: #1b4e9b !important;
        }
      }
    }
    .NavbarSec{
      margin-top: 20px;
       color: #fff;
       background-color: #f8fbff !important;
       height: 50px;
     }
  }

}