@mixin Sub-Head {
  font-size: 30px;
  font-weight: 500;
}
@mixin Para {
  font-size: 14px;
  font-weight: 400;
}
.RegistrationMain {
  padding-top: 150px;
  .TopSection {
    background-color: #f5f8fd;
    padding: 40px;
    h1 {
      text-align: center;
      font-size: 35px;
      font-weight: 500;
      padding-bottom: 30px;
    }
    .InnerContent {
      padding: 0px 150px;
      img {
        width: 100%;
      }
      .NewFlexParagraph {
        display: flex;
        justify-content: stretch;
        text-align: center;
        p {
          @include Para();
          color: #1b4e9b;
         margin-inline-start: 30px;
         
        }
      }
    }
  }
  .TextInsideContent {
    padding: 50px 0;
    h2 {
     @include Sub-Head();
      line-height: 2;
    }
    h6{
      color: #363636;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 15px
    }
    p {
      @include Para();
      color: #363636;
    }
    .TextPoints {
      position: relative;
      margin-left: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: -20px;
        height: 6px;
        width: 6px;
        border-radius: 20px;
        background-color: #1b4e9b;
      }
    }
    .TextColorPoint {
      position: relative;
      margin-left: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: -20px;
        height: 6px;
        width: 6px;
        border-radius: 20px;
        background-color: #bccf31;
      }
    }
    .TextPointsBold {
      @include Para();
      font-size: 15px;
      font-weight: 500 !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .RegistrationMain {
    .TopSection {
      h1 {
        padding-bottom: 20px;
      }
      .InnerContent {
        padding: 0px 20px;

        .NewFlexParagraph {
          display: block;
         
        }
      }
    }
  }
}
