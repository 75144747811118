.GalleryMainSec {
padding-bottom: 40px;
  .SliderBox {

    position: relative;
    margin: auto;
    overflow: hidden;

    img{
      transition: 0.9s;
    }
    &:hover img{
      transform: scale(1.5);
    }
  }
  .GallerySecTwo {
    padding-top: 15px !important;
  }

  .FlexButtons {
    position: relative;
    display: flex;
    margin-bottom: 30px !important;
    .FristButton {
      text-align: center;
      background-color: #1b4e9b;
      color: #fff;
      border-radius: 20px;
      padding: 10px 10px !important;
      width: 100px;
      font-size: 13px;
      border: none;
    }
    .SeccondButton {
      position: absolute;
      left: 75px;
      text-align: center;
      background-color: transparent;
     border: 1px solid #e5e5e5;
      color: #000;
      border-radius: 20px;
      padding: 10px 10px !important;
      width: 120px;
      font-size: 13px;
      z-index: -1;
    }
  }
}
.GalleryMainTab{
  padding-top: 20px;
}