@mixin Para {
  font-size: 14px;
  font-weight: 400;
}
.FaqSectionMain{
    padding-top: 180px;
    padding-bottom: 60px;
    h1 {
        
        font-size: 30px;
        font-weight: 500;
        padding-bottom: 30px;
      }
      p {
       @include Para();
        color: #0d0d0d;
      }
      h6{
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
      }
      .FaqContent{
        margin: 10px 0;
      }
    //   .AccordianBorder{
    //     border-top: 1px #ddd solid !important;
    //   }
    
}