@mixin paragraph {
  font-size: 14px;
  font-weight: 400;
}
.CommonWidth {
  p {
    color: #454545;
    @include paragraph();
    font-size: 12px;
  }
  h3 {
    font-size: 21px;
    font-weight: 500;
    color: #4b4a4a;
    margin-bottom: 20px;
    text-align: center;
  }
  .RedText {
    color: #ff5757;
    font-size: 12px;
  }
  .otp {
    font-weight: 400;
  }
  .thatText {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    p {
      color: #575454;
      font-size: 15px;
      cursor: pointer;
    }
  }
  .Onediv {
    padding-top: 15px;
  }

  .Small {
    display: flex;
    margin: 15px 0;
    justify-content: space-between;
    .SmallBox {
      width: 50px;
      height: 50px;
      text-align: center;
    }
  }
}

.registerForm {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.loginMessage {
  display: flex;
  justify-content: center;
  padding: 20px;
  button {
    width: 100px;
    border: 0;
    padding: 10px 5px 10px 5px;
    background: #1b4e9b;
    color: white;
  }
}

.popoverStyle{
  padding: 10px;
  p{
    font-size: 13px;
    border-bottom: 1px solid gainsboro;
    color: red;
  }
  .active{
    font-size: 13px;
    color: green;
    border-bottom: 1px solid gainsboro;
  }
}

.eyeIconwrapper{
  display: flex;
  position: relative;
  span{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 20;
  }
}
