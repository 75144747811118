@mixin Sub-Head {
  font-size: 28px;
  font-weight: 500;
}
@mixin Para {
  font-size: 14px;
  font-weight: 400;
}
.ContactMainSec {
 padding-top: 200px;
 padding-bottom: 40px;
  span {
    height: 250px;
    background-color: #dfe7f2;
    width: 1px;
  }
  .Click{
    cursor: pointer;
  }

  .ContactSec {
    display: flex;
    justify-content: space-between;
    text-align: center;
    background-color: #f7faff;
    padding: 60px 50px;
    margin-bottom: 20px;

    h1 {
     @include Sub-Head();
      margin-bottom: 10px;
    }
    h2 {
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .Text {
      margin-top: 20px;
    }
    P {
     @include Para();
      color: #292828;
    }
    h6 {
     @include Para();
      font-weight: 600;
      line-height: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .ContactMainSec {
    padding: 40px 0;
    span {
      display: none;
    }

    .ContactSec {
      display: block;
      text-align: center;
      padding: 10px 10px;
     
    }
    .ContentInside{
        margin-bottom: 20px;
        margin-top: 40px;
    }
  }
}
