.VedioSecMain {
  padding-top: 140px;
  h1 {
    font-size: 30px;
    font-family: 500;
    margin-bottom: 30px;
  }

  .VedioSecWrap {
    margin-bottom: 10px;

    .VedioBox {
      display: flex;
      text-align: center;
      .Box {
        border-radius: 10px;
        position: relative;

        margin-right: 30px;
        .TextBox {
          padding: 15px 0;
          h6 {
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
          }
        }
        .PlayerButton {
          cursor: pointer;
          position: absolute;
          bottom: 90px;
          left: 75px;
          z-index: 1 !important;
          width: 200px;
          height: 200px;
          background-image: url("../../../public/assets/images/player-icon.svg");
          background-repeat: no-repeat;
          background-size: 70px;
          background-position: center;
          button {
            border: none;
            background: transparent;
          }
          img {
            width: 70px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .VedioSecMain {
    .VedioSecWrap {
      margin-bottom: 10px;

      .VedioBox {
        display: block;
        text-align: center;
        .Box {
          margin-right: 0px;
        }
      }
    }
  }
}
