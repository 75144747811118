@mixin Sub-Head {
  font-size: 40px;
  font-weight: 600;
}
@mixin Para {
  font-size: 14px;
  font-weight: 400;
}

.LandingPageMain {
  font-family: "Poppins", sans-serif;
  background-color: #f8fbff;
  padding-top: 160px;
  .MainBanner {
    padding-top: 30px;
    display: flex;

    img {
      width: 100%;
    }
    h1 {
      font-size: 76px;
      font-weight: 600;
      line-height: 1;
      span {
        color: #1b4e9b;
      }
    }
    h2 {
      font-size: 40px;
      font-weight: 300;
      line-height: 1;
    }
    .LeftSec {
      flex-basis: 50%;
      padding: 100px 0;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 80px;
        height: 80px;
        background-image: url("../../public/assets/images/rocket-icon.svg");
        background-repeat: no-repeat;
        background-size: 40px;
      }
     
    }
    .RightSec {
      flex-basis: 70%;
    }
  }
}

.AboutSecMain {
  padding: 60px 0;
  .AboutInnerSec {
    display: flex;
    .AboutLeftSec {
      flex-basis: 50%;
    }
    .AboutRightSec {
      padding: 50px;
      flex-basis: 70%;
      h1 {
        @include Sub-Head();
        margin-bottom: 20px;
        span {
          color: #1b4e9b;
        }
      }
      p {
        @include Para();
        color: #5b5959;
       
      }
    }
  }
}

.VedioSecMain {
  padding-bottom: 80px;
  text-align: center;

  .VedioSecWrap {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      left: -80px;
      bottom: -30px;
      width: 200px;
      height: 200px;
      background-image: url("../../public/assets/images/circle-img.svg");
      background-repeat: no-repeat;
      background-size: 180px;
      z-index: -1;
    }
    &::after {
      position: absolute;
      content: "";
      right: 0px;
      top: -60px;
      width: 100px;
      height: 100px;
      background-image: url("../../public/assets/images/back-img.svg");
      background-repeat: no-repeat;
      background-size: 70px;
    }
    h1 {
      @include Sub-Head();
      margin-bottom: 30px;
      span {
        color: #1b4e9b;
      }
    }
    .VedioBox {
      display: flex;
      text-align: center;
      .Box {
        border-radius: 10px;
        position: relative;
        margin-right: 30px;
      
        .TextBox {
          padding: 15px 0;
          h6 {
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
          }
          p {
            @include Para();
            line-height: 1;
          }
        }
        .PlayerButton {
          cursor: pointer;
          position: absolute;
          bottom: 110px;
          left: 75px;
          z-index: 1 !important;
          width: 200px;
          height: 200px;
          background-image: url("../../public/assets/images/player-icon.svg");
          background-repeat: no-repeat;
          background-size: 70px;
          background-position: center;
          border: none;
            background-color: transparent ;
        
          img {
            width: 70px;
          }
        }
      }
    }
  }
}

.BrandWrapper{
 background-color: 
 
 #f8fbff;
 padding: 40px 0;
  h1{
    @include Sub-Head();
    text-align: center;
    margin-bottom: 30px;
    span {
      color: #1b4e9b;
    }
  }
  .BrandBox{
    .Img{
      margin-top: 20px;
    }
    .ImgL{
     width: 80px;
    }
  }
  
 
    
  .BrandAsoSec{
margin-top: 40px;
  }

}

.GalleryMain{
  padding: 40px 0;
  .SliderBox{
   padding-right: 15px !important;
  }
  .GallerySecTwo{
    padding-top: 15px !important;
  }
  h1{
    @include Sub-Head();
    text-align: center;
    line-height: 2;
  }
 
}



@media screen and (max-width: 768px) {
  .LandingPageMain {
    .MainBanner {
      display: block;

      h1 {
        font-size: 40px;
      }
      h2 {
        font-size: 25px;
      }
      .LeftSec {
        padding: 60px 0;

        &::before {
          top: 10px;
        }
      }
    }
  }

  .AboutSecMain {
    padding: 40px 0;
    .AboutInnerSec {
      display: block;

      .AboutRightSec {
        padding: 20px;
      }
    }
  }

  .VedioSecMain {
    padding-bottom: 40px;
   
    .VedioSecWrap {
      &::before {
        display: none;
      }
      &::after {
        top: -70px;
      }

      .VedioBox {
        display: block;
        text-align: center;
        .Box {
          margin-right: 0px !important;
        }
      }
    }
  }

  .BrandWrapper{
    background-color: 
    
    #f8fbff;
    padding: 40px 0;
     h1{
       @include Sub-Head();
       text-align: center;
       margin-bottom: 30px;
       span {
         color: #1b4e9b;
       }
     }
     .BrandBox{
      text-align: center;
       .Img{
         margin-top: 0px;
       }
       .ImgL{
        width:unset;
       }
     }
     
    
       
     .BrandAsoSec{
   margin-top: 30px;
     }
   
   }
}
