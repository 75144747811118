@mixin Sub-Head {
  font-size: 30px;
  font-weight: 500;
}
@mixin Para {
  font-size: 14px;
  font-weight: 400;
}
.AssociateInstitutes {
  padding-top: 170px;
  overflow: hidden;
 padding-bottom: 40px;
  .TableSection {
    .table {
      width: 100%;
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;

      border-right: 1px solid #dddddd;
     @include Para();
    }
    thead {
      background-color: #1b4e9b;
      color: #fff;
    }
  }
  h2 {
    @include Sub-Head();
    line-height: 2;
  }
  p {
   @include Para();
    color: #363636;
  }
  h6 {
    font-size: 14px;
    font-weight: 600;
  }
  .TextInnPoint {
    .TextPoints {
      position: relative;
      margin-left: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: -20px;
        height: 6px;
        width: 6px;
        border-radius: 20px;
        background-color: #1b4e9b;
      }
      span{
        font-weight: 500;
      }
     
    }
  }
  .TextPointsBold {
    font-weight: 500;
    @include Para();
  }
}
@media screen and (max-width: 768px) {
  .AssociateInstitutes {
    overflow: hidden;
    .TableSection {
      overflow: scroll !important;
    }
  }
}
